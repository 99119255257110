import React, { useState, useRef, useEffect } from "react";
import {
  AnimatePresence,
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import {
  ChevronDown,
  Globe,
  Building2,
  Cog,
  Bot,
  Music,
  Presentation,
  Factory,
  Utensils,
  Building,
  CreditCard,
  Sparkles,
} from "lucide-react";
import { Card, CardContent } from "../../components/ui/Card";

import { Button } from "../../components/ui/Button";
import Layout from "../layout";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";
import { Img } from "../../components/ui/Img";
import ScrollDownAnimation from "./scroll-down-animation";
import BackgroundMusic from "../../components/BackGroundMusic";
import CampSchedule from "./camp-schedule";
import CampDetails from "./camp-details";
import { Dialog } from "@radix-ui/react-dialog";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/Dialog";

interface Activity {
  day: number | string;
  title: string;
  description: string;
  icon: React.ReactNode;
  youtubeLinks: string[];
}

interface RoadTripStageData {
  id: string;
  location: string;
  title: string;
  days: string;
  description: string;
  activities: Activity[];
  image: string;
  color: string;
  images: string[];
}

const roadTripStages: RoadTripStageData[] = [
  {
    id: "KOREA CULTURE",
    location: "Cultural Immersion",
    title: "Welcome & Cultural Experience",
    days: "1-5",
    description:
      "Begin your journey with Korean culture, language, and industry insights.",
    activities: [
      {
        day: "1-2",
        title: "Welcome, Orientation, and Korean Culture Overview",
        description: ` Welcome and introduction to the program
            Overview of Korean culture.
            Team-building activities to foster connections.`,
        icon: <Globe className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/96zu5cAo6Rc?si=O-n7EVqy9VhqdWj9",
          "https://www.youtube.com/embed/Naezik_Qv4A?si=WDyJvqrMiqVhoTFj",
        ],
      },
      {
        day: "3",
        title: "Korean Culinary Experience",
        description:
          "Cooking class to make Korean dishes like kimchi and tokbokki",
        icon: <Utensils className="h-5 w-5" />,
        youtubeLinks: [
          "https://youtube.com/embed/Zf0K6dYFbx0?si=Aor_ZPtFLXjDEk2a",
        ],
      },
      {
        day: "4",
        title: "Traditional Village Visit",
        description:
          "Explore a Vietnamese Lu Dynasty Clan Village and participate in  Amusement park ",
        icon: <Building2 className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/ajXdiyIIyi8?si=nUvoEbgVtPs3MJM2",
          "https://www.youtube.com/embed/4hzMw8yL5Z4?si=4AQxCKeLIpQB3vQ9",
        ],
      },
      {
        day: "5",
        title: "Industry Excursion",
        description:
          "Tour Hyundai's facilities to learn about automotive innovations",
        icon: <Factory className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/g-zhYIo9K5Q?si=LQWqaHyFOyZFmqnS",
        ],
      },
    ],
    image: "Images/Camping/section1/section1-1.jpg",
    color: "from-[#87CEEB] to-[#FFD700]",
    images: [
      "Images/Camping/section1/section1-1.jpg",
      "Images/Camping/section1/section1-2.jpg",
      "Images/Camping/section1/section1-3.jpg",
    ],
  },
  {
    id: "STEM AI",
    location: "STEAM & Technology",
    title: "Innovation & Engineering",
    days: "5-7",
    description:
      "Dive into STEAM, AI, and Robotics with hands-on workshops and industry visits.",
    activities: [
      {
        day: "5",
        title: "STEM Workshop",
        description:
          "Introduction to engineering principles with hands-on challenges",
        icon: <Cog className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/F7FkTfjfm5s?si=XGAgdePsyKUdvOiC",
        ],
      },
      {
        day: "6",
        title: "AI & Robotics",
        description: "Learn AI basics and build a simple robot using coding",
        icon: <Bot className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/F7FkTfjfm5s?si=XGAgdePsyKUdvOiC",
        ],
      },
      {
        day: "7",
        title: "Industry Visit - Apple Developer Academy @POSTECH ",
        description:
          "Tour of Apple Developer Academy @POSTECH to understand industrial innovation",
        icon: <Factory className="h-5 w-5" />,
        youtubeLinks: [
          "https://youtube.com/embed/-DyAz1_phqc?si=aFuOEBJeZK36DnKn",
        ],
      },
    ],
    image: "Images/Camping/section2/section2-1.jpg",
    color: "from-[#87CEEB] to-[#FFD700]",
    images: [
      "Images/Camping/section2/section2-1.jpg",
      "Images/Camping/section2/section2-2.jpg",
      "Images/Camping/section2/section2-3.jpg",
    ],
  },
  {
    id: "K-POP",
    location: "K-pop & Performance",
    title: "Creative Expression",
    days: "8-9",
    description:
      "Express yourself through K-pop dance, vocal training, and video production.",
    activities: [
      {
        day: "8-9",
        title: "K-pop Workshop and Music Video Project",
        description: `Learn K-pop choreography and vocal techniques with professional instructors
          Film a group music video to showcase skills developed during the week`,
        icon: <Music className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/PEPLtn5RRB8?si=DVvEVAhbVqy3peyl",
        ],
      },
    ],
    image: "Images/Camping/section3/section3-1.jpg",
    color: "from-[#87CEEB] to-[#FFD700]",
    images: [
      "Images/Camping/section3/section3-1.jpg",
      "Images/Camping/section3/section3-2.jpg",
      "Images/Camping/section3/section3-3.jpg",
    ],
  },
  {
    id: "COMPETITION",
    location: "Competition",
    title: "Competition and Awards Ceremony",
    days: "10",
    description: "Compete in RoboticAI and celebrate your journey.",
    activities: [
      {
        day: "10",
        title: "Competition",
        description: "Team work competition and celebrate your journey",
        icon: <Presentation className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/wV3pkHbk4ZQ?si=l-ac5mXaZU8gBc8f",
        ],
      },
    ],
    image: "Images/Camping/section4/section4-1.jpg",
    color: "from-[#87CEEB] to-[#FFD700]",
    images: [
      "Images/Camping/section4/section4-1.jpg",
      "Images/Camping/section4/section4-2.jpg",
      "Images/Camping/section4/section4-3.jpg",
    ],
  },
];

interface IntroductionSectionProps {
  roadTripStages: RoadTripStageData[];
  scrollToStage: (index: number) => void;
}

const IntroductionSection: React.FC<IntroductionSectionProps> = ({
  roadTripStages,
  scrollToStage,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrls, setVideoUrls] = useState<string[]>([]);

  const openModal = (urls: string[]) => {
    setVideoUrls(urls);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setVideoUrls([]);
  };
  return (
    <section className="relative py-16 md:py-24 lg:py-32 overflow-hidden bg-gradient-to-br from-yellow-300/30 to-blue-400/30">
      <div className="absolute inset-0 backdrop-blur-xl"></div>
      <div className="absolute inset-0 bg-white/50 dark:bg-gray-900/50"></div>
      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto bg-white/90 dark:bg-gray-800/90 rounded-3xl p-8 sm:p-12 font-lexend shadow-2xl border-2 border-dashed border-yellow-300 dark:border-blue-400">
          <div className="flex items-center justify-center mb-8">
            <Sparkles className="w-8 h-8 text-yellow-500 mr-3" />
            <h2 className="text-4xl sm:text-5xl font-bold text-gray-900 dark:text-white text-center">
              Welcome to Your Adventure
            </h2>
          </div>
          <p className="text-lg sm:text-xl text-gray-700 dark:text-gray-300 leading-relaxed mb-10 text-center max-w-3xl mx-auto">
            Our Korea Summer Camp 2025 offers an immersive experience into
            Korean culture, language, and cutting-edge technology. Tailored for
            ages 10-17, our program combines cultural exploration, K-pop
            workshops, STEAM & AI robotics, and exclusive industry excursions.{" "}
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8 m-auto">
            <Card className="bg-gradient-to-br from-yellow-100 to-yellow-200 dark:from-yellow-900 dark:to-yellow-800 transform hover:scale-105 transition-transform duration-300">
              <CardContent className="p-6 sm:p-8 flex flex-col items-center text-center h-full">
                <Globe className="w-12 h-12 mb-4 text-yellow-600 dark:text-yellow-400" />

                <h3 className="text-lg sm:text-xl font-semibold mb-3 text-gray-900 dark:text-white">
                  Cultural Immersion
                </h3>
                <Img
                  src="/Images/Camping/HeaderKpop.png"
                  alt="Cultural Immersion"
                  className="w-full h-32 object-cover mb-4 rounded-lg"
                />
                <p className="text-base text-gray-700 dark:text-gray-300 mb-6 flex-grow">
                  Experience authentic Korean culture and language through
                  interactive workshops and excursions.
                </p>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() =>
                        openModal([
                          "./video/culture.mp4",
                          "./video/culture2.mp4",
                        ])
                      }
                      className="mt-auto bg-yellow-500 text-white hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700 border-none"
                    >
                      Explore Culture
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-white-a700 p-4 sm:p-8 rounded-lg shadow-lg max-w-full sm:max-w-4xl w-full">
                    <DialogHeader>
                      <DialogTitle>Culture</DialogTitle>
                      <DialogDescription>
                        <div
                          className={`video-container ${
                            videoUrls.length === 1
                              ? "flex justify-center"
                              : "grid grid-cols-1 md:grid-cols-2 gap-4"
                          }`}
                        >
                          {videoUrls.map((url, index) => (
                            <iframe
                              key={index}
                              width="100%"
                              height="315"
                              src={url}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={`Video ${index + 1}`}
                            ></iframe>
                          ))}
                        </div>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>

            <Card className="bg-gradient-to-br from-blue-100 to-blue-200 dark:from-blue-900 dark:to-blue-800 transform hover:scale-105 transition-transform duration-300">
              <CardContent className="p-6 sm:p-8 flex flex-col items-center text-center h-full">
                <Bot className="w-12 h-12 mb-4 text-blue-600 dark:text-blue-400" />
                <h3 className="text-xl sm:text-2xl font-semibold mb-3 text-gray-900 dark:text-white">
                  Tech Innovation
                </h3>
                <Img
                  src="/Images/Camping/AI-robotic.jpg"
                  alt="/Images/Camping/AI-robotic.jpg"
                  className="w-full h-32 object-cover mb-4 rounded-lg"
                />
                <p className="text-base text-gray-700 dark:text-gray-300 mb-6 flex-grow">
                  Dive into STEAM, AI, and robotics with hands-on projects and
                  industry visits.
                </p>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => openModal(["./video/AI-robotic.mp4"])}
                      className="mt-auto bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 border-none"
                    >
                      Discover Tech
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-white-a700 p-4 sm:p-8 rounded-lg shadow-lg max-w-full sm:max-w-4xl w-full">
                    <DialogHeader>
                      <DialogTitle>AI</DialogTitle>
                      <DialogDescription>
                        <div
                          className={`video-container ${
                            videoUrls.length === 1
                              ? "flex justify-center"
                              : "grid grid-cols-1 md:grid-cols-2 gap-4"
                          }`}
                        >
                          {videoUrls.map((url, index) => (
                            <iframe
                              key={index}
                              width="100%"
                              height="315"
                              src={url}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={`Video ${index + 1}`}
                            ></iframe>
                          ))}
                        </div>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>

            <Card className="bg-gradient-to-br from-yellow-100 to-yellow-200 dark:from-yellow-900 dark:to-yellow-800 transform hover:scale-105 transition-transform duration-300">
              <CardContent className="p-6 sm:p-8 flex flex-col items-center text-center h-full">
                <Music className="w-12 h-12 mb-4 text-yellow-600 dark:text-yellow-400" />
                <h3 className="text-xl sm:text-2xl font-semibold mb-3 text-gray-900 dark:text-white">
                  K-pop Experience
                </h3>
                <Img
                  src="/Images/Camping/section3/section3-3.jpg"
                  alt="K-pop Experience"
                  className="w-full h-32 object-cover mb-4 rounded-lg"
                />
                <p className="text-base text-gray-700 dark:text-gray-300 mb-6 flex-grow">
                  Dance, sing, and create music videos with professional K-pop
                  instructors.
                </p>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => openModal(["./video/kpop.mp4"])}
                      className="mt-auto bg-yellow-500 text-white hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700 border-none"
                    >
                      Join K-pop
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-white-a700 p-4 sm:p-8 rounded-lg shadow-lg max-w-full sm:max-w-4xl w-full">
                    <DialogHeader>
                      <DialogTitle>Video</DialogTitle>
                      <DialogDescription>
                        <div
                          className={`video-container ${
                            videoUrls.length === 1
                              ? "flex justify-center"
                              : "grid grid-cols-1 md:grid-cols-2 gap-4"
                          }`}
                        >
                          {videoUrls.map((url, index) => (
                            <iframe
                              key={index}
                              width="100%"
                              height="315"
                              src={url}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={`Video ${index + 1}`}
                            ></iframe>
                          ))}
                        </div>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

const allPartners = [
  {
    id: "GBIT",
    title: "Best Choice",
    image: "/Images/logo/GBICT.png",
  },
  {
    id: "meta",
    title: "Premium Quality",
    image: "/Images/meta-square.png",
  },
  {
    id: "DCU",
    title: "1st Choice",
    image: "/Images/logo/dcu.png",
  },
  {
    id: "metaPlus",
    title: "MetaPlus",
    image: "/Images/logo/metaplus.jpg", // No image for MetaPlus, just text
  },
];

const PartnersSection: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef, { once: true });
  const controls = useAnimation();

  // Create duplicated array with unique keys for infinite scroll
  const partners = [
    ...allPartners,
    ...allPartners.map((partner) => ({
      ...partner,
      id: `${partner.id}-duplicate`,
    })),
    ...allPartners.map((partner) => ({
      ...partner,
      id: `${partner.id}-duplicate-2`,
    })),
  ];

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <section
      className="py-16 md:py-24 bg-white overflow-hidden"
      ref={containerRef}
    >
      <div className="container mx-auto px-4 max-w-7xl">
        <motion.div
          className="text-center mb-12 md:mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <motion.h2
            className="text-3xl md:text-5xl font-bold text-gray-900 mb-4 md:mb-6"
            initial={{ opacity: 0, y: -20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Our Trustful Partners
          </motion.h2>
        </motion.div>

        <div className="relative w-full overflow-hidden">
          <motion.div
            className="flex "
            style={{
              gap: isMobile ? "8px" : "24px",
              width: `${
                partners.length * (isMobile ? 200 : 300) +
                (partners.length - 1) * 96
              }px`,
            }}
            animate={{
              x: [
                `0px`,
                `-${
                  allPartners.length * (isMobile ? 200 : 300) +
                  (allPartners.length - 1) * 96
                }px`,
              ],
            }}
            transition={{
              x: {
                repeat: Infinity,
                duration: 20,
                ease: "linear",
              },
            }}
          >
            {partners.map((partner) => (
              <motion.div
                key={partner.id}
                className={`relative ${
                  isMobile ? "w-[200px]" : "w-[300px]"
                } aspect-square flex items-center justify-center p-8 rounded-xl bg-white`}
                whileHover={{ scale: 1.05 }}
              >
                <div className="relative w-full h-full flex items-center justify-center">
                  {partner.image ? (
                    <img
                      src={partner.image}
                      alt={partner.title}
                      className={`${
                        isMobile ? "w-[150px] h-[150px]" : "w-[200px] h-[200px]"
                      } object-contain`}
                    />
                  ) : (
                    <span
                      className={`absolute inset-0 flex items-center justify-center ${
                        isMobile ? "text-2xl" : "text-4xl"
                      } font-bold text-black`}
                    >
                      {partner.title}
                    </span>
                  )}
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const RegistrationForm: React.FC = () => {
  return (
    <section className="py-12 sm:py-16 lg:py-20 bg-gradient-to-b from-yellow-300/10 to-blue-400/10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-yellow-300">
            Register Your Interest
          </h2>
          <div className="flex justify-center">
            <Card className="bg-white-a700 shadow-lg w-full max-w-md">
              <CardContent className="p-6 flex flex-col items-center justify-center">
                <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-blue-400">
                  Register Online
                </h3>
                <a
                  href="https://docs.google.com/forms/d/10W8qGHl_X6ej4grEQfaoOFgPjcHmNo3ijz-ybzFo5lk/edit"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full bg-gradient-to-r from-yellow-300 to-blue-400 hover:from-yellow-300 hover:to-blue-400 text-white-a700 text-center py-3 rounded-lg"
                >
                  Register NOW!
                </a>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};
const backgroundImages = [
  // "/Images/Camping/Camping5.jpg",
  "/Images/Camping/Camping6.jpg",
  "/Images/Camping/Camping7.jpg",
  // "/Images/Camping/Camping.jpg",
  // "/Images/Camping/Camping2.jpg",
  // "/Images/Camping/Camping3.jpg",
  // "/Images/Camping/Camping4.jpg",

  // Add more image paths as needed
];
const SummerCamp: React.FC = () => {
  const [activeStage, setActiveStage] = useState(1);
  const stageRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    stageRefs.current = stageRefs.current.slice(0, roadTripStages.length);
  }, []);

  const scrollToStage = (index: number) => {
    const element = stageRefs.current[index];
    if (element) {
      const yOffset = -100;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = stageRefs.current.length - 1; i >= 0; i--) {
        const element = stageRefs.current[i];
        if (element && element.offsetTop <= scrollPosition) {
          setActiveStage(i + 1);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Layout>
      <div className="min-h-screen">
        {/* Hero Section */}

        <section className="relative min-h-screen overflow-hidden pt-16 md:pt-16">
          <div className="absolute inset-0 backdrop-blur-3xl"></div>

          {/* New background image with blur effect */}
          {backgroundImages.map((image, index) => (
            <div
              key={image}
              className="absolute inset-0 z-0 transition-opacity duration-1000 ease-in-out"
              style={{
                backgroundImage: `url('${image}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: index === currentImageIndex ? 1 : 0,
              }}
            ></div>
          ))}
          <div
            className="absolute inset-0 z-0 bg-black opacity-50"
            // style={{
            //   backdropFilter: "blur(10px)",
            // }}
          ></div>

          <div className="relative  backdrop-blur-sm bg-black/30 rounded-xl container mx-auto px-4 py-12 md:py-20 z-10">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="flex flex-col justify-center items-center gap-8 mb-16 md:mb-32 min-h-[calc(100vh-200px)]"
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="text-center mb-8 md:mb-12 "
              >
                <p className="text-lg sm:text-xl md:text-2xl text-white-a700 font-medium font-lexend mb-2 ">
                  <span className=""> Language, Culture, K-pop, and Tech</span>
                </p>
                <h2 className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-extrabold tracking-wider font-lexend ">
                  <span className="text-white-a700 ">All in One </span>
                  <span className="text-white-a700">Summer Camp</span>
                </h2>
                {/* <audio controls muted autoPlay loop>
                  <source src="/music/SummerCamBG.mp3" type="audio/mpeg" />
                </audio> */}
              </motion.div>

              <div className="flex flex-col space-y-8 w-full items-center">
                <motion.h1
                  className="text-4xl sm:text-5xl md:text-6xl lg:text-[4.5rem] text-white-a700 font-extrabold tracking-wider leading-tight font-lexend text-center "
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                >
                  <span>Experience</span> Korea
                  <br />
                  Like Never Before!
                  <motion.span
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6, delay: 1 }}
                    className="inline-block ml-2"
                    style={{
                      textShadow: "4px 4px 4px rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    ↗
                  </motion.span>
                </motion.h1>
                <motion.ul
                  className="flex flex-wrap gap-3 justify-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.6 }}
                >
                  {roadTripStages.map((stage, index) => (
                    <li key={stage.id}>
                      <Button
                        variant="outline"
                        size="lg"
                        className="text-white-a700 border-white-a700 hover:bg-blue-300 hover:text-white-a700 transition-colors font-lexend duration-300 whitespace-nowrap font-bold"
                        onClick={() => scrollToStage(index)}
                      >
                        {stage.id}
                      </Button>
                    </li>
                  ))}
                </motion.ul>
                <motion.h2
                  className="text-white-a700 font-bold text-2xl md:text-3xl text-center font-lexend"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.8 }}
                >
                  JUNE - JULY 2025
                  <ScrollDownAnimation onClick={scrollToContent} />
                </motion.h2>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Introduction Section */}
        <IntroductionSection
          roadTripStages={roadTripStages}
          scrollToStage={scrollToStage}
        />
        <PartnersSection />

        <main className="container mx-auto px-4 py-8">
          <section className="text-center mb-16 md:mb-24">
            <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold text-yellow-300 mb-4">
              Your Adventure Awaits
            </h2>
            <p className="text-lg sm:text-xl text-gray-700 mb-6">
              Embark on a journey through culture, technology, and creativity in
              this unique camping adventure!
            </p>
            <Button
              size="lg"
              className="bg-[#87CEEB] text-white-a700 hover:bg-[#5F9EA0] px-6 py-3 sm:px-8 sm:py-4 text-base sm:text-lg rounded-full shadow-lg"
              onClick={() => {
                const section = document.getElementById("target-section");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              Start Your Journey
              <ChevronDown className="ml-2 h-4 w-4 sm:h-5 sm:w-5" />
            </Button>
          </section>

          {/* Timeline and Stages */}
          <div className="relative flex font-mono">
            <div className="space-y-8 " id="target-section">
              <CampSchedule />
              <CampDetails />
            </div>
          </div>
        </main>
      </div>
      <BackgroundMusic />
    </Layout>
  );
};

export default SummerCamp;
